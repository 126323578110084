.technology {
  .technology-top {
    background-color: #f7f4ff;
    display: flex;
    flex-direction: column;
    .technology-title {
      font-size: 3.6458vw;
      font-weight: bold;
      line-height: normal;
      color: #7f4efb;
      margin-top: 8.8021vw;
      margin-left: 18.75vw;
    }
    .technology-content {
      width: 62.5vw;
      font-size: 1.4583vw;
      font-weight: 350;
      line-height: 3.125vw;
      color: #060023;
      margin-top: 1.6667vw;
      margin-left: 18.75vw;
    }
    .technology-img {
      width: 55.5729vw;
      height: 44.0104vw;
      margin: 0.9375vw auto 0;
    }
  }
  .technology-bottom {
    width: 100%;
    height: 64.1146vw;
    background: linear-gradient(342deg, rgba(0, 0, 0, 0) 0%, #8065fe 82%),
      #0b002c;
    display: flex;
    user-select: none;
    .technology-list {
      height: 47.3958vw;
      width: 62.5vw;
      margin: 7.7604vw auto 8.9583vw;
      display: flex;
      flex-direction: column;
      .items-row {
        display: flex;
        justify-content: space-between;
        .tech-item {
          width: 15.625vw;
          height: 18.75vw;
          border-radius: 1.0417vw;
          opacity: 1;
          padding: 2.0833vw;
          background: #ffffff;
          .number {
            font-size: 7.8125vw;
            font-weight: bold;
            line-height: 7.8125vw;
            color: #7f4efb;
          }
          .title {
            font-size: 2.0833vw;
            font-weight: bold;
            line-height: normal;
            color: #060023;
            margin-top: 2.6042vw;
            margin-bottom: 0.5729vw;
          }
          .desc {
            width: 15.8333vw;
            height: 3.6458vw;
            font-size: 1.25vw;
            font-weight: 350;
            line-height: normal;
            color: #060023;
          }
        }
        .technology-human {
          width: 19.4792vw;
          height: 36.3542vw;
          margin-top: -13.4375vw;
          // margin-left: 3.125vw;
        }
      }

      .top-row {
        margin-bottom: 1.5625vw;
      }
    }
  }
}

@primary-color: #1DA57A;