.home {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f7f4ff;
  user-select: none;
  display: flex;
  flex-direction: column;
  .home-shadow {
    position: absolute;
    width: 223.75vw;
    height: auto;
    top: 3.3333vw;
    left: -64.5833vw;
    z-index: 1;
  }
  .home-title-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    .home-title {
      font-size: 3.6458vw;
      font-weight: bold;
      line-height: normal;
      text-align: center;
      color: #7f4efb;
      margin: 8.8021vw auto 0.8333vw;
    }
    .home-desc {
      font-size: 1.4583vw;
      font-weight: 350;
      color: #060023;
      margin: auto;
    }
    .home-start {
      width: 12.9167vw;
      height: 3.75vw;
      border-radius: 0.4167vw;
      background: linear-gradient(180deg, #7559fe 0%, #5636f3 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2.5vw auto 11.7188vw;
      font-size: 1.25vw;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.3s ease;
      background-size: 100% 200%;
      background-position: 0 0;
      &:hover {
        background-position: 0 100%;
        transform: translateY(-0.1042vw);
        box-shadow: 0 0.2083vw 0.625vw rgba(117, 89, 254, 0.3);
      }
      text-decoration: none;
    }
  }
  .home-language {
    position: relative;
    z-index: 3;
    width: 62.5vw;
    height: 41.25vw;
    margin: 0 auto 18.5938vw;
    .home-language-title {
      font-weight: 700;
      font-size: 1.875vw;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      .home-language-title-number {
        font-size: 3.3333vw;
      }
    }
    .language-list {
      width: 15.8854vw;
      height: 28.8021vw;
      display: flex;
      flex-direction: column;
      border-radius: 1.0417vw;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(4.6875vw);
      box-shadow: 0px 0.4167vw 1.0417vw 0.1042vw rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      top: 5.6896vw;
      z-index: 5;
      .language-item {
        width: 12.9167vw;
        height: 4.7917vw;
        border-radius: 0.9375vw;
        background: #ffffff;
        box-shadow: 0px 0.0964vw 0.1641vw 0px rgba(0, 0, 0, 0.0048);
        display: flex;
        align-items: center;
        margin: 1.4583vw auto 0;
        .language-flag {
          width: 2.2917vw;
          height: 2.2917vw;
          border-radius: 50%;
          margin-left: 1.3542vw;
          margin-right: 1.1979vw;
        }
        .language-name {
          font-size: 1.4583vw;
          font-weight: 350;
          color: #060023;
        }
      }
      .language-more {
        font-size: 1.4583vw;
        font-weight: bold;
        line-height: normal;
        color: #ffffff;
        margin: 1.1354vw auto 0vw;
      }
    }
    .home-ai-desc {
      font-size: 1.4583vw;
      font-weight: 350;
      color: #ffffff;
      position: absolute;
      left: 24.4792vw;
      top: 8.9063vw;
    }
    .home-center-img {
      width: 52.0833vw;
      height: 27.0833vw;
      position: absolute;
      left: 10.4167vw;
      bottom: 0;
    }
  }
  .home-function {
    margin-left: 18.75vw;
    margin-top: 10.5938vw;
    .home-function-title {
      font-size: 3.6458vw;
      font-weight: bold;
      color: #7f4efb;
    }
    .home-features {
      margin-top: 2.3958vw;
      .feature-item {
        font-size: 1.4583vw;
        font-weight: 350;
        line-height: 3.125vw;
        color: #060023;
      }
    }
    .home-service {
      display: flex;
      align-items: center;
      margin-top: 5.2083vw;
      .service-list {
        width: 53.125vw;
        display: flex;
        justify-content: space-between;
        .service-icon {
          width: 9.375vw;
          height: 10.4167vw;
          border-radius: 1.0417vw;
        }
      }
      .service-more {
        font-size: 1.4583vw;
        font-weight: bold;
        color: #7f4efb;
        margin-left: 3.1771vw;
      }
    }
  }
  .home-how-use {
    width: 100%;
    height: 67.7604vw;
    margin-top: 13.5417vw;
    background: #0b002c;
    display: flex;
    flex-direction: column;
    align-items: center;
    .home-how-use-title {
      font-size: 3.6458vw;
      font-weight: bold;
      color: #ffffff;
      margin: 6.0938vw auto 1.3021vw;
    }
    .home-how-use-desc {
      font-size: 1.4583vw;
      font-weight: 350;
      line-height: normal;
      color: #ffffff;
      margin-bottom: 2.1875vw;
    }
    .home-how-use-img {
      width: 62.5vw;
      height: 32.4479vw;
      border-radius: 2vw;
    }
    .home-how-use-start {
      width: 12.9167vw;
      height: 3.75vw;
      border-radius: 0.4167vw;
      background: linear-gradient(180deg, #7559fe 0%, #5636f3 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25vw;
      font-weight: 500;
      color: #ffffff;
      margin-top: 6.4063vw;
      cursor: pointer;
      transition: all 0.3s ease;
      background-size: 100% 200%;
      background-position: 0 0;

      &:hover {
        background-position: 0 100%;
        transform: translateY(-0.1042vw);
        box-shadow: 0 0.2083vw 0.625vw rgba(117, 89, 254, 0.3);
      }
      text-decoration: none;
      color: inherit;
    }
  }
  .home-bottom {
    width: 100%;
    height: 66.1458vw;
    display: flex;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #e0d3ff 100%
    );
    .home-bottom-list {
      width: 62.6042vw;
      display: flex;
      justify-content: space-between;
      margin: 10.4167vw auto 8.5417vw;
      .home-bottom-img {
        width: 19.7917vw;
        height: 46.1458vw;
      }
    }
  }
}

@primary-color: #1DA57A;