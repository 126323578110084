.about {
  background-color: #f7f4ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .about-title {
    font-size: 3.6458vw;
    font-weight: bold;
    line-height: normal;
    color: #7f4efb;
    margin: 8.8vw auto 1.6667vw;
  }
  .about-text {
    width: 62.5vw;
    margin: 0 auto;
    font-size: 1.4583vw;
    font-weight: 350;
    line-height: 3.15vw;
    color: #060023;
  }
  .about-info {
    width: 62.5vw;
    height: 42.1354vw;
    margin: 5.4167vw auto 9.4792vw;
    position: relative;
    user-select: none;
    z-index: 5;
    .info-card {
      position: absolute;
      background: #ffffff;
      border-radius: 1.0417vw;
      box-shadow: 0px 1.0417vw 1.0417vw 0.1042vw rgba(0, 0, 0, 0.1);
      .info-title {
        font-size: 2.0833vw;
        font-weight: bold;
        line-height: 2.0833vw;
        color: #060023;
      }
      .map-icon {
        width: 30.7292vw;
        height: 23.5938vw;
        margin-top: 2.0833vw;
      }
      .email-icon {
        width: 4.1667vw;
        height: 4.1667vw;
        margin-top: 2.7604vw;
        margin-bottom: 2.0833vw;
      }
      .email-text {
        font-size: 1.4583vw;
        font-weight: 350;
        line-height: 1.4583vw;
        color: #060023;
        margin-top: 1.0417vw;
      }
    }
  }
  .address {
    padding: 3.125vw;
    width: 30.7292vw;
    height: 27.7604vw;
    left: 0;
    top: 0;
  }
  .email {
    width: 21.9792vw;
    height: 17.1354vw;
    left: 33.8542vw;
    bottom: 0;
    padding-left: 3.125vw;
  }
  .about-bg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .about-shadow {
    position: absolute;
    width: 50vw;
    height: auto;
    bottom: -0.917vw;
    left: -5.4583vw;
    z-index: 1;
  }
}

@primary-color: #1DA57A;