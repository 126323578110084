.footer {
  width: 100%;
  height: 3.75vw;
  background-color: #0c0f31;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-content {
    display: flex;
    justify-content: center;
    .copyright-icon {
      width: 1.4063vw;
      height: 1.25vw;
      margin-right: 0.4167vw;
    }
    .copyright-text {
      font-size: 0.8333vw;
      font-weight: normal;
      color: #fff;
      a {
        display: block;
        color: #fff;
        text-align: center;
      }
    }
  }
}

@primary-color: #1DA57A;