.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.1667vw;
  background-color: #f7f4ff;
  z-index: 100;
  display: flex;
  align-items: center;
  user-select: none;
  .logoImg {
    width: 5.3646vw;
    height: 1.7708vw;
    margin-left: 19.375vw;
  }

  .nav-menu {
    display: flex;
    gap: 5.6563vw;
    margin-left: 8.5938vw;
    .nav-item {
      text-decoration: none;
      font-size: 0.8333vw;
      font-weight: normal;
      line-height: normal;
      color: #000000;
      width: 3.6458vw;
      &:hover {
        color: #7f4efb;
      }
    }
  }

  .start-btn {
    width: 5.7292vw;
    height: 2.0833vw;
    border-radius: 0.4167vw;
    background: linear-gradient(180deg, #7559fe 0%, #5636f3 100%);
    color: white;
    border: none;
    font-size: 0.8333vw;
    font-weight: 350;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5vw;
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      background: linear-gradient(180deg, #5536f1 0%, #5636f3 100%);
    }
  }
}

.qrcode-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  // 添加动画效果
  animation: fadeIn 0.3s ease-in-out;

  .qrcode-content {
    animation: scaleIn 0.3s ease-in-out;
  }
}

// 定义动画
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.qrcode-content {
  background: white;
  padding: 1.5vw 2.0417vw;
  border-radius: 0.4167vw;
  text-align: center;

  h3 {
    margin-bottom: 0.8333vw;
    font-size: 1.25vw;
  }

  img {
    max-width: 15.625vw;
    margin: 1.0417vw 0;
  }
}

@primary-color: #1DA57A;